import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

const Grid = ({ data }) => {
  return (
    <section className="textures-grid">
      <div className="container-fluid">
        <motion.h3 {...fadeAnimation}>Tekstury</motion.h3>
        <div className="row">
          {data?.map((item, index) => (
            <div className="col-md-4 col-sm-6" key={index}>
              <motion.div className="textures-grid__item" {...fadeAnimation}>
                <div className="textures-grid__item-thumbnail">
                  <div
                    className="textures-grid__item-thumbnail-image"
                    style={{
                      backgroundImage: `url('${item?.featuredImage?.node?.localFile?.publicURL}')`,
                    }}
                  />
                  <div
                    className="textures-grid__item-thumbnail-image textures-grid__item-thumbnail-image--zoom"
                    style={{
                      backgroundImage: `url('${item?.acfTexture?.zoomImage?.localFile?.publicURL}')`,
                    }}
                  />
                </div>
                <h5>{item.title}</h5>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Grid
