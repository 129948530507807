import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import Bestsellers from "components/Bestsellers"

import { Content, Grid } from "page_components/textures"

const Textures = ({ data }) => {
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout>
      <Breadcrumbs title="Tekstury" />
      <PageHeader
        title={pageData?.acfTexturesPage?.header}
        content={pageData?.acfTexturesPage?.subHeader}
      />
      <Content pageData={pageData} />
      <Grid data={data?.allWpTexture?.nodes} />
      <Bestsellers />
    </Layout>
  )
}

export const Head = ({ data }) => (
  <Seo
    title={
      data?.allWpPage?.nodes[0]?.seo?.title
        ? data?.allWpPage?.nodes[0]?.seo?.title
        : data?.allWpPage?.nodes[0]?.title
    }
    description={
      data?.allWpPage?.nodes[0]?.seo?.metaDesc &&
      data?.allWpPage?.nodes[0]?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpTexture(sort: { fields: date, order: DESC }) {
      nodes {
        title
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfTexture {
          zoomImage {
            localFile {
              publicURL
            }
          }
          thumbnailImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allWpPage(filter: { id: { eq: "cG9zdDo3NTI2" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
        acfTexturesPage {
          header
          subHeader
          description
          slider {
            textureLabel
            textureImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

export default Textures
